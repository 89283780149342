import getS3Instance from ".";

export const DeleteFunction = async (file) => {
    return new Promise(async (resolve, reject) => {
        const params = {
            Key: file,
        };
        try {
            const s3 = await getS3Instance();
            let result = await s3.deleteObject(params).promise();
            if(result.$response.httpResponse.statusCode === 204) {
                resolve('Image Deleted')
            } else {
                console.log('Error in Image Delete')
                console.log(result)
                reject('Error in Image Delete')
            }
        } catch(error) {
            console.log(error)
            reject('Something bad happen')
        }
    })
}
