import getS3Instance from ".";

export const UploadFunction = async (file, path, privateFile) => {
    if(typeof file === 'object' && !Array.isArray(file)) {
        return new Promise(async (resolve, reject) => {
            let params = {
                Key: path,
                ContentType: file.type,
                Body: file
            }

            if(!privateFile) {
                params.ACL = 'public-read'
            }

            try {
                const s3 = await getS3Instance();
                let result = await s3.putObject(params).promise();
                if(result.$response.httpResponse.statusCode === 200) {
                    resolve("https://assets-denali.s3.amazonaws.com/" + path)
                } else {
                    console.log('Something bad happen')
                    console.log(result)
                    reject('Something bad happen')
                }
            } catch(error) {
                console.log(error)
                reject('Something bad happen')
            }
        })
    } else {
        return file
    }
}
