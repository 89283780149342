import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react'
import { Button, Col, Form, Row } from 'react-bootstrap'
import Loader from '../components/Loader'
import { UploadFunction } from '../utils/s3/uploadImage';
import { DeleteFunction } from '../utils/s3/deleteImage';
import { toast } from 'react-toastify';

const imageDomain = "https://assets-denali.s3.amazonaws.com/"
let deleteImages = []

const StepSix = forwardRef((props, ref) => {
    const { activeData, setMainData, setActiveStep, isMainLoader, setIsMainLoader } = props

    const [isSubmitted, setIsSubmitted] = useState(false);
    const [isPetSubmitted, setIsPetSubmitted] = useState(false);
    const [isQualifiedSubmitted, setIsQualifiedSubmitted] = useState(false);
    const [addPets, setAddPets] = useState(activeData?.isPets === "Yes" ? false : true);
    const [addQualified, setAddQualified] = useState(activeData?.isQualified === "Yes" ? false : true);
    const [editPets, setEditPets] = useState(false);
    const [editQualified, setEditQualified] = useState(false);
    const [isVehicleSubmitted, setIsVehicleSubmitted] = useState(false);
    const [addVehicles, setAddVehicles] = useState(activeData?.isVehicles === "Yes" ? false : true);
    const [editVehicles, setEditVehicles] = useState(false);
    const [vehicleLoading, setVehicleLoading] = useState(false);
    const [totalVehicle, setTotalVehicle] = useState(1);
    const [isAnyError, setIsAnyError] = useState({ value: false, isCheck: false })
    const [userID, setUserID] = useState('')
    const [isPetImageLoader, setIsPetImageLoader] = useState(false)
    const [isQualifiedImageLoader, setIsQualifiedImageLoader] = useState(false)
    const [data, setData] = useState({
        pets: [],
        isPets: "",
        qualified: [],
        isQualified: "",
        vehicles: [],
        isVehicles: "",
        isFishTank: "",
        fishTank: "",
        isSmoking: "",
        specialRequest: "",
        isAnySpecialRequest: "",
        adaEsaDoc: ""
    })

    const [petData, setPetData] = useState({
        type: "",
        breed: "",
        age: "",
        weight: "",
        spayedOrNeutered: "Neutered",
        image: []
    })

    const [qualifiedData, setQualifiedData] = useState({
        type: "",
        breed: "",
        age: "",
        weight: "",
        spayedOrNeutered: "Neutered",
        image: []
    })

    const [vehicleData, setVehicleData] = useState({
        name: "",
        model: "",
        color: "",
        year: "",
        licensePlate: ""
    })

    useEffect(() => {
        if(activeData) {
            setData(activeData)
        }
        let user = JSON.parse(localStorage.getItem('userData'))
        setUserID(user?.id)
    }, [])

    useEffect(() => {
        if(isAnyError.isCheck) {
            if(
                (data.pets.length === 0 && data.isPets === "") ||
                (data.qualified.length === 0 && data.isQualified === "") ||
                (data.vehicles.length === 0 && data.isVehicles === "") ||
                (!data.specialRequest && data.isAnySpecialRequest === "") ||
                (data.isSmoking === "") ||
                (data.isFishTank === "" && !data.fishTank?.trim() ||
                    (data.isQualified === "Yes" && !data.adaEsaDoc))
            ) setIsAnyError({ value: true, isCheck: true })
            else setIsAnyError({ value: false, isCheck: true })
        }
    }, [data])

    useImperativeHandle(ref, () => {
        return getData()
    }, [data]);

    const getData = () => {
        let dataObj = {
            step: 6,
            stepData: data,
        }
        return dataObj;
    };

    const handleNext = async (e) => {
        e.preventDefault()
        setIsSubmitted(true)
        if(
            (data.pets.length === 0 && data.isPets === "") ||
            (data.qualified.length === 0 && data.isQualified === "") ||
            (data.vehicles.length === 0 && data.isVehicles === "") ||
            (!data.specialRequest && data.isAnySpecialRequest === "") ||
            (data.isSmoking === "") ||
            (data.isFishTank === "" && !data.fishTank?.trim() ||
                (data.isQualified === "Yes" && !data.adaEsaDoc)) ||
            (data.pets.length === 0 && data.isPets === "Yes")
        ) {
            setIsAnyError({ value: true, isCheck: true })
            return
        }

        setIsMainLoader(true)

        for(const image of deleteImages) {
            if(image) {
                await DeleteFunction(image)
            }
        }

        const myData = { ...data }
        // if(myData.isPets === "Yes") {
        //     let myPetsData = [...myData.pets]

        //     for(const petItem of myPetsData) {
        //         let imageList = []
        //         for(let i = 0; i < petItem.image.length; i++) {
        //             let file = petItem.image[i];
        //             let path = `${userID}/${new Date().getTime()}_${petItem.image[i].name}`
        //             let imageData = await UploadFunction(file, path);
        //             imageList = [...imageList, imageData]
        //         }
        //         petItem.image = imageList
        //     }

        //     myData.pets = myPetsData
        // }

        // if(myData.isQualified === "Yes") {
        // if(typeof myData.adaEsaDoc === "object") {
        //     const signatureUrl = await UploadFunction(myData.adaEsaDoc, `${new Date().getTime()}/_ada_esa_doc`);
        //     myData.adaEsaDoc = signatureUrl
        // }

        // let myQualifiedData = [...myData.qualified]

        // for(const qualifiedItem of myQualifiedData) {
        //     let imageList = []
        //     for(let i = 0; i < qualifiedItem.image.length; i++) {
        //         let file = qualifiedItem.image[i];
        //         let path = `${userID}/${new Date().getTime()}_${qualifiedItem.image[i].name}`
        //         let imageData = await UploadFunction(file, path);
        //         imageList = [...imageList, imageData]
        //     }
        //     qualifiedItem.image = imageList
        // }

        // myData.qualified = myQualifiedData
        deleteImages = []
        if(myData.isVehicles === "Yes") { await setMainData(6, { ...myData, totalVehicle }) }
        else { await setMainData(6, myData) }
        setActiveStep(7)
        // } else {
        //     deleteImages = []
        //     if(myData.isVehicles === "Yes") { await setMainData(6, { ...myData, totalVehicle }) }
        //     else { await setMainData(6, myData) }
        //     setActiveStep(7)
        // }
    }

    const handlePetSave = async () => {
        setIsPetSubmitted(true)
        setIsPetImageLoader(true)
        if(!petData.type || !petData.age || !petData.weight || petData.image.length === 0) {
            return
        }
        let imageList = []
        for(let i = 0; i < petData.image.length; i++) {
            let file = petData.image[i];
            let path = `${userID}/${new Date().getTime()}_${petData.image[i].name}`
            let imageData = await UploadFunction(file, path);
            imageList = [...imageList, imageData]
        }
        setData({ ...data, pets: [...data.pets, { ...petData, image: imageList }] })
        setAddPets(false)
        setEditPets(false)
        await setMainData(6, { ...data, pets: [...data.pets, { ...petData, image: imageList }] })
        setPetData({ type: "", breed: "", age: "", weight: "", spayedOrNeutered: "Neutered", image: [] })
        setIsPetImageLoader(false)
        setIsPetSubmitted(false)
    }

    const handleQualifiedSave = async () => {
        setIsQualifiedSubmitted(true)
        setIsQualifiedImageLoader(true)
        if(!qualifiedData.type || !qualifiedData.age || !qualifiedData.weight || qualifiedData.image.length === 0) {
            return
        }
        let imageList = []
        for(let i = 0; i < qualifiedData.image.length; i++) {
            let file = qualifiedData.image[i];
            let path = `${userID}/${new Date().getTime()}_${qualifiedData.image[i].name}`
            let imageData = await UploadFunction(file, path);
            imageList = [...imageList, imageData]
        }
        setData({ ...data, qualified: [...data.qualified, { ...qualifiedData, image: imageList }] })
        setAddQualified(false)
        setEditQualified(false)
        await setMainData(6, { ...data, qualified: [...data.qualified, { ...qualifiedData, image: imageList }] })
        setQualifiedData({ type: "", breed: "", age: "", weight: "", spayedOrNeutered: "Neutered", image: [] })
        setIsQualifiedImageLoader(false)
        setIsQualifiedSubmitted(false)
    }

    const handleVehilceSave = async () => {
        setIsVehicleSubmitted(true)
        if(!vehicleData.name || !vehicleData.model || !vehicleData.color || !vehicleData.year || !vehicleData.licensePlate) {
            return
        }
        setVehicleLoading(true)

        setData({ ...data, vehicles: [...data.vehicles, vehicleData] })
        setVehicleData({ name: "", model: "", color: "", year: "", licensePlate: "" })
        setAddVehicles(false)
        setEditVehicles(false)
        setIsVehicleSubmitted(false)
        setVehicleLoading(false)
    }

    const handleEdit = (key, item, i) => {
        setData({ ...data, [key]: data[key].filter((item, index) => index !== i) })
        if(key === "pets") {
            setPetData(item)
            setAddPets(false)
            setEditPets(true)
        } else if(key === "qualified") {
            setQualifiedData(item)
            setAddQualified(false)
            setEditQualified(true)
        } else if(key === "vehicles") {
            setVehicleData(item)
            setAddVehicles(false)
            setEditVehicles(true)
        }
    }

    const handleDelete = (key, index) => {
        if(key === "pets") {
            if(data[key].length === 1) { setAddPets(true) }
            for(const image of data[key][index].image) {
                if(typeof image === "string") {
                    const file = image.split(imageDomain)
                    deleteImages.push(file[1])
                }
            }
        }
        else if(key === "qualified") {
            if(data[key].length === 1) { setAddQualified(true) }
            for(const image of data[key][index].image) {
                if(typeof image === "string") {
                    const file = image.split(imageDomain)
                    deleteImages.push(file[1])
                }
            }
        }
        else if(key === "vehicles" && data[key].length === 1) {
            setAddVehicles(true)
        }

        setData({ ...data, [key]: data[key].filter((item, i) => index !== i) })
    }

    const removePetImage = async (index) => {
        const updatedImages = [...petData.image];

        if(editPets) {
            const deleteItem = updatedImages.splice(index, 1)[0];
            if(typeof deleteItem === "string") {
                const file = deleteItem.split(imageDomain)
                deleteImages.push(file[1])
            }
        } else {
            updatedImages.splice(index, 1);
        }

        setPetData({ ...petData, image: updatedImages });
    }

    const removeQualifiedImage = async (index) => {
        const updatedImages = [...qualifiedData.image];

        if(editQualified) {
            const deleteItem = updatedImages.splice(index, 1)[0];
            if(typeof deleteItem === "string") {
                const file = deleteItem.split(imageDomain)
                deleteImages.push(file[1])
            }
        } else {
            updatedImages.splice(index, 1);
        }

        setQualifiedData({ ...qualifiedData, image: updatedImages });
    }

    const deleteSignature = async () => {
        if(typeof data.adaEsaDoc === "string") {
            const file = data.adaEsaDoc.split(imageDomain)
            deleteImages.push(file[1])
        }
        setData({ ...data, adaEsaDoc: "" })
    }

    const handleImage = async (e, name) => {
        if(!e.target.files[0]) return
        const file = e.target.files[0];
        const fileType = file.type;

        if(name !== "adaEsa") {
            if(!fileType.startsWith('image/')) {
                toast.error('Please upload only image file.')
                return;
            }
        }

        if(name === "pet") {
            setPetData({ ...petData, image: [...petData.image, e.target.files[0]] })
        } else if(name === "qualified") {
            setQualifiedData({ ...qualifiedData, image: [...qualifiedData.image, e.target.files[0]] })
        } else {
            const signatureUrl = await UploadFunction(e.target.files[0], `${userID}/${new Date().getTime()}_${e.target.files[0].name}`);
            setData({ ...data, adaEsaDoc: signatureUrl })
        }
    }

    const handleAddBtn = (name) => {
        if(name === "pet") {
            setAddPets(true)
            setPetData({ type: "", breed: "", age: "", weight: "", spayedOrNeutered: "Neutered", image: [] })
        } else if(name === "qualified") {
            setAddQualified(true)
            setQualifiedData({ type: "", breed: "", age: "", weight: "", spayedOrNeutered: "Neutered", image: [] })
        } else {
            setAddVehicles(true)
            setVehicleData({ type: "", breed: "", age: "", weight: "" })
        }
    }

    const deletePetImages = () => {
        for(const item of data.pets) {
            for(const image of item.image) {
                if(typeof image === "string") {
                    const file = image.split(imageDomain)
                    deleteImages.push(file[1])
                }
            }
        }
    }

    const deleteQualifiedImages = () => {
        for(const item of data.qualified) {
            for(const image of item.image) {
                if(typeof image === "string") {
                    const file = image.split(imageDomain)
                    deleteImages.push(file[1])
                }
            }
        }

        if(typeof data.adaEsaDoc === "string") {
            const file = data.adaEsaDoc.split(imageDomain)
            deleteImages.push(file[1])
        }
    }

    const getFileExtension = (url) => {
        return url.split(".").pop().toLowerCase();
    };

    const getFileName = (url) => {
        const lastSegment = url.split('/').pop();
        const underscoreIndex = lastSegment.indexOf('_');
        if (underscoreIndex !== -1) {
            return lastSegment.slice(underscoreIndex + 1);
        }
        return decodeURIComponent(lastSegment);
    };

    return (
        <>
            <section className='step-section step-6'>
                <p><b>Property: &nbsp;</b> {localStorage.getItem('propertyName')}</p>
                <h3 className='step-title mb-3'>General Information</h3>
                <Form onSubmit={handleNext}>
                    <h6 className="mt-4 mb-2 question">Will you have any animals living with you at this property?</h6>
                    <Form.Check
                        inline
                        label="Yes"
                        name="livingProperty"
                        type='radio'
                        id="livingPropertyYes"
                        checked={data.isPets === 'Yes'}
                        onChange={() => setData({ ...data, isPets: 'Yes' })}
                        isInvalid={!data.isPets && isSubmitted}
                    />
                    <Form.Check
                        inline
                        label="No"
                        name="livingProperty"
                        type="radio"
                        id="livingPropertyNo"
                        checked={data.isPets === 'No'}
                        onChange={() => {
                            setData({ ...data, isPets: 'No', pets: [] })
                            handleAddBtn("pet")
                            deletePetImages()
                        }}
                        isInvalid={!data.isPets && isSubmitted}
                    />
                    {!data.isPets && isSubmitted && <Form.Control.Feedback type="invalid" className="text-danger error-msg d-block">Please select an option.</Form.Control.Feedback>}
                    {
                        data.isPets === 'Yes' &&
                        <>
                            {data.pets.map((item, index) =>
                                <div key={index} className='applying-item'>
                                    <div>
                                        <h4 className='application-full-name d-flex align-items-start justify-content-between'>
                                            <div className='application-name'>{item.type}</div>
                                        </h4>
                                        <p className='mb-1'><span className='box-sub-title'>Breed: </span>{item.breed}</p>
                                        <p className='mb-1'><span className='box-sub-title'>Age: </span>{item.age} years</p>
                                        <p className='mb-1'><span className='box-sub-title'>Weight: </span>{item.weight} pounds</p>
                                        <p className='mb-1'><span className='box-sub-title'>Spayed / Neutered: </span>{item.spayedOrNeutered}</p>
                                        {
                                            data.pets[index].image.map((datas, i) => {
                                                return (
                                                    <div key={i} className='border-bottom py-2'>
                                                        <div className='d-flex align-items-center uploaded-img'>
                                                            <img src={typeof datas === "string" ? datas : URL.createObjectURL(datas)} />
                                                            <div className='img-name-show'>{typeof datas === "string" ? getFileName(datas) : datas.name}</div>
                                                            <div className='d-flex ms-auto'>
                                                                <a href={typeof datas === "string" ? datas : URL.createObjectURL(datas)} target='_blank' className='d-block p-1'>
                                                                    <svg xmlns="http://www.w3.org/2000/svg" width="18px" height="20px" viewBox="0 0 24 24" fill="none"><g id="style=linear"><g id="eye-open"><path id="vector" d="M15 12C15 13.6592 13.6592 15 12 15C10.3408 15 9 13.6592 9 12C9 10.3408 10.3408 9 12 9C13.6592 9 15 10.3408 15 12Z" stroke="#033A6D" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" /><path id="vector_2" d="M12 19.27C15.53 19.27 18.82 17.4413 21.11 14.2764C22.01 13.0368 22.01 10.9532 21.11 9.71356C18.82 6.54861 15.53 4.71997 12 4.71997C8.46997 4.71997 5.17997 6.54861 2.88997 9.71356C1.98997 10.9532 1.98997 13.0368 2.88997 14.2764C5.17997 17.4413 8.46997 19.27 12 19.27Z" stroke="#033A6D" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" /></g></g></svg>
                                                                </a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )
                                            })
                                        }
                                        {!editPets && <div className='d-flex justify-content-between mt-3'>
                                            <button onClick={() => handleEdit("pets", item, index)} type='button' className='action-btn'>
                                                <svg width="21" height="21" color="#033A6D" viewBox="0 0 21 21" fill="#033A6D"><path d="M18.458.7l1.846 1.845a2.394 2.394 0 010 3.382l-.602.601-.012.016a.664.664 0 01-.015.014l-1.212 1.211-.015.017a.664.664 0 01-.015.015L6.96 19.268a.091.091 0 01-.01.012l-.214.212a.652.652 0 01-.312.174l-5.622 1.32a.652.652 0 01-.784-.785l1.319-5.621a.652.652 0 01.174-.313l.207-.208.01-.011a.665.665 0 01.012-.011L14.454 1.32l.01-.009.611-.612a2.393 2.393 0 013.383 0zM2.422 15.664l-.894 3.811 3.811-.895-2.917-2.916zM13.689 3.929L3.113 14.509l3.38 3.381 10.57-10.571-3.374-3.39zm1.233-1.231l-.311.309 3.375 3.389.318-.316-3.382-3.382zm1.075-1.075l-.153.153 3.382 3.382.155-.153a1.09 1.09 0 000-1.538l-1.845-1.844a1.089 1.089 0 00-1.539 0z"></path></svg>
                                                Edit
                                            </button>
                                            <button onClick={() => handleDelete("pets", index)} type='button' className='action-btn'>
                                                <svg width="18" height="20" color="#033A6D" viewBox="0 0 18 20" fill="#033A6D"><path d="M12.445 0c.357 0 .646.274.646.612l-.001 1.836 4.317.001a.6.6 0 01.585.513l.008.1a.603.603 0 01-.593.611h-1.044v15.715a.622.622 0 01-.63.612H2.267a.622.622 0 01-.632-.612V3.673H.593a.6.6 0 01-.585-.512L0 3.06c0-.338.266-.612.593-.612l4.316-.001V.612C4.91.274 5.2 0 5.555 0h6.89zm2.656 3.673H2.9v15.103H15.1V3.673zm-9.456 1.94c.34 0 .614.273.614.611v9.796a.613.613 0 01-1.227 0V6.224c0-.338.275-.612.613-.612zm3.273 0c.34 0 .614.273.614.611v9.796a.613.613 0 01-1.227 0V6.224c0-.338.274-.612.613-.612zm3.273 0c.339 0 .614.273.614.611v9.796a.613.613 0 01-1.228 0V6.224c0-.338.275-.612.614-.612zm-.392-4.389H6.201V2.45h5.598V1.224z"></path></svg>
                                                Delete
                                            </button>
                                        </div>}
                                    </div>
                                </div>
                            )}
                            {
                                (addPets || editPets) &&
                                <div className='applying-form position-relative'>
                                    {
                                        data.pets.length !== 0 &&
                                        <button onClick={() => setAddPets(false)} type='button' className='action-btn delete-form-btn'>
                                            <svg className='m-0' width="18" height="20" color="#033A6D" viewBox="0 0 18 20" fill="#033A6D"><path d="M12.445 0c.357 0 .646.274.646.612l-.001 1.836 4.317.001a.6.6 0 01.585.513l.008.1a.603.603 0 01-.593.611h-1.044v15.715a.622.622 0 01-.63.612H2.267a.622.622 0 01-.632-.612V3.673H.593a.6.6 0 01-.585-.512L0 3.06c0-.338.266-.612.593-.612l4.316-.001V.612C4.91.274 5.2 0 5.555 0h6.89zm2.656 3.673H2.9v15.103H15.1V3.673zm-9.456 1.94c.34 0 .614.273.614.611v9.796a.613.613 0 01-1.227 0V6.224c0-.338.275-.612.613-.612zm3.273 0c.34 0 .614.273.614.611v9.796a.613.613 0 01-1.227 0V6.224c0-.338.274-.612.613-.612zm3.273 0c.339 0 .614.273.614.611v9.796a.613.613 0 01-1.228 0V6.224c0-.338.275-.612.614-.612zm-.392-4.389H6.201V2.45h5.598V1.224z"></path></svg>
                                        </button>
                                    }
                                    <Row>
                                        <Form.Group as={Col} md="12" className='mb-3'>
                                            <Form.Label>Type (Dog, Cat, etc.)</Form.Label>
                                            <Form.Control
                                                type="text"
                                                name="petType"
                                                value={petData.type}
                                                onChange={(e) => setPetData({ ...petData, type: e.target.value })}
                                                isInvalid={!petData.type && isPetSubmitted}
                                            />
                                            <Form.Control.Feedback type="invalid" className="text-danger error-msg">type is required</Form.Control.Feedback>
                                        </Form.Group>
                                        <Form.Group as={Col} md="12" className='mb-3'>
                                            <Form.Label>Breed (Optional)</Form.Label>
                                            <Form.Control
                                                type="text"
                                                name="breed"
                                                value={petData.breed}
                                                onChange={(e) => setPetData({ ...petData, breed: e.target.value })}
                                            // isInvalid={!petData.breed && isPetSubmitted}
                                            />
                                            {/* <Form.Control.Feedback type="invalid" className="text-danger error-msg">breed is required</Form.Control.Feedback> */}
                                        </Form.Group>
                                        <Form.Group as={Col} md="6" className='mb-3'>
                                            <Form.Label>Age (Years)</Form.Label>
                                            <Form.Control
                                                type="number"
                                                name="age"
                                                value={petData.age}
                                                onChange={(e) => setPetData({ ...petData, age: e.target.value })}
                                                isInvalid={!petData.age && isPetSubmitted}
                                            />
                                            <Form.Control.Feedback type="invalid" className="text-danger error-msg">age is required</Form.Control.Feedback>
                                        </Form.Group>
                                        <Form.Group as={Col} md="6" className='mb-3'>
                                            <Form.Label>Weight (Lbs)</Form.Label>
                                            <Form.Control
                                                type="number"
                                                name="weight"
                                                value={petData.weight}
                                                onChange={(e) => setPetData({ ...petData, weight: e.target.value })}
                                                isInvalid={!petData.weight && isPetSubmitted}
                                            />
                                            <Form.Control.Feedback type="invalid" className="text-danger error-msg">weight is required</Form.Control.Feedback>
                                        </Form.Group>
                                        <Form.Group as={Col} md="12" className='mb-3'>
                                            <Form.Label>Spayed / Neutered</Form.Label>
                                            <Form.Check
                                                inline
                                                label="Spayed"
                                                name="spayedOrNeuteredPet"
                                                type='radio'
                                                id="spayedOrNeuteredYes"
                                                checked={petData.spayedOrNeutered === 'Spayed'}
                                                onChange={() => setPetData({ ...petData, spayedOrNeutered: 'Spayed' })}
                                            />
                                            <Form.Check
                                                inline
                                                label="Neutered"
                                                name="spayedOrNeuteredPet"
                                                type="radio"
                                                id="spayedOrNeuteredNo"
                                                checked={petData.spayedOrNeutered === 'Neutered'}
                                                onChange={() => setPetData({ ...petData, spayedOrNeutered: 'Neutered' })}
                                            />
                                            <Form.Check
                                                inline
                                                label="Neither"
                                                name="spayedOrNeuteredPet"
                                                type="radio"
                                                id="spayedOrNeuteredNo"
                                                checked={petData.spayedOrNeutered === 'Neither'}
                                                onChange={() => setPetData({ ...petData, spayedOrNeutered: 'Neither' })}
                                            />
                                        </Form.Group>
                                        <Form.Group as={Col} md="12" className='mb-3 mt-2'>
                                            {petData.image &&
                                                petData.image.map((item, i) => {
                                                    return (
                                                        <div key={i} className='border-bottom py-3 mb-2'>
                                                            <div className='d-flex align-items-center uploaded-img'>
                                                                <img src={typeof item === "string" ? item : URL.createObjectURL(item)} />
                                                                <div>{typeof item === "string" ? getFileName(item) : item.name}</div>
                                                                <div className='d-flex ms-auto'>
                                                                    <a href={typeof item === "string" ? item : URL.createObjectURL(item)} target='_blank' className='d-block p-1'>
                                                                        <svg xmlns="http://www.w3.org/2000/svg" width="18px" height="20px" viewBox="0 0 24 24" fill="none"><g id="style=linear"><g id="eye-open"><path id="vector" d="M15 12C15 13.6592 13.6592 15 12 15C10.3408 15 9 13.6592 9 12C9 10.3408 10.3408 9 12 9C13.6592 9 15 10.3408 15 12Z" stroke="#033A6D" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" /><path id="vector_2" d="M12 19.27C15.53 19.27 18.82 17.4413 21.11 14.2764C22.01 13.0368 22.01 10.9532 21.11 9.71356C18.82 6.54861 15.53 4.71997 12 4.71997C8.46997 4.71997 5.17997 6.54861 2.88997 9.71356C1.98997 10.9532 1.98997 13.0368 2.88997 14.2764C5.17997 17.4413 8.46997 19.27 12 19.27Z" stroke="#033A6D" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" /></g></g></svg>
                                                                    </a>
                                                                    <span className='d-block p-1 ms-1' onClick={() => removePetImage(i)}>
                                                                        <svg width="18" height="20" color="#033A6D" viewBox="0 0 18 20" fill="#033A6D"><path d="M12.445 0c.357 0 .646.274.646.612l-.001 1.836 4.317.001a.6.6 0 01.585.513l.008.1a.603.603 0 01-.593.611h-1.044v15.715a.622.622 0 01-.63.612H2.267a.622.622 0 01-.632-.612V3.673H.593a.6.6 0 01-.585-.512L0 3.06c0-.338.266-.612.593-.612l4.316-.001V.612C4.91.274 5.2 0 5.555 0h6.89zm2.656 3.673H2.9v15.103H15.1V3.673zm-9.456 1.94c.34 0 .614.273.614.611v9.796a.613.613 0 01-1.227 0V6.224c0-.338.275-.612.613-.612zm3.273 0c.34 0 .614.273.614.611v9.796a.613.613 0 01-1.227 0V6.224c0-.338.274-.612.613-.612zm3.273 0c.339 0 .614.273.614.611v9.796a.613.613 0 01-1.228 0V6.224c0-.338.275-.612.614-.612zm-.392-4.389H6.201V2.45h5.598V1.224z"></path></svg>
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )
                                                })
                                            }
                                            <div className='d-flex position-relative flex-wrap'>
                                                <input type='file' className='input-file' id="petPhoto" value="" accept="image/*" onChange={(e) => handleImage(e, "pet")} />
                                                <label className="action-btn" htmlFor='petPhoto'>
                                                    <svg width="18" height="18" viewBox="0 0 18 18"><path d="M9 16.7C13.3 16.7 16.7 13.3 16.7 9 16.7 4.7 13.3 1.3 9 1.3 4.7 1.3 1.3 4.7 1.3 9 1.3 13.3 4.7 16.7 9 16.7ZM9 18C4 18 0 14 0 9 0 4 4 0 9 0 14 0 18 4 18 9 18 14 14 18 9 18ZM9.8 8.2L12.2 8.2C12.6 8.2 13 8.6 13 9 13 9.4 12.6 9.8 12.2 9.8L9.8 9.8 9.8 12.2C9.8 12.6 9.4 13 9 13 8.6 13 8.2 12.6 8.2 12.2L8.2 9.8 5.8 9.8C5.4 9.8 5 9.4 5 9 5 8.6 5.4 8.2 5.8 8.2L8.2 8.2 8.2 5.8C8.2 5.4 8.6 5 9 5 9.4 5 9.8 5.4 9.8 5.8L9.8 8.2Z"></path></svg>
                                                    Upload Image
                                                </label>
                                                {petData.image.length === 0 && isPetSubmitted && <Form.Control.Feedback type="invalid" className="text-danger error-msg d-block w-100">image is required</Form.Control.Feedback>}
                                            </div>
                                        </Form.Group>
                                    </Row>
                                    <Button onClick={handlePetSave} type="button" className="text-uppercase border-btn d-block mx-auto mt-2" disabled={isPetImageLoader}>{isPetImageLoader ? 'Loading...' : 'Save'}</Button>
                                </div>
                            }
                            {
                                !addPets && !editPets &&
                                <button onClick={() => handleAddBtn("pet")} type='button' className='action-btn'>
                                    <svg className="kzsFVxnNUwoBGhZVibsb" width="18" height="18" viewBox="0 0 18 18"><path d="M9 16.7C13.3 16.7 16.7 13.3 16.7 9 16.7 4.7 13.3 1.3 9 1.3 4.7 1.3 1.3 4.7 1.3 9 1.3 13.3 4.7 16.7 9 16.7ZM9 18C4 18 0 14 0 9 0 4 4 0 9 0 14 0 18 4 18 9 18 14 14 18 9 18ZM9.8 8.2L12.2 8.2C12.6 8.2 13 8.6 13 9 13 9.4 12.6 9.8 12.2 9.8L9.8 9.8 9.8 12.2C9.8 12.6 9.4 13 9 13 8.6 13 8.2 12.6 8.2 12.2L8.2 9.8 5.8 9.8C5.4 9.8 5 9.4 5 9 5 8.6 5.4 8.2 5.8 8.2L8.2 8.2 8.2 5.8C8.2 5.4 8.6 5 9 5 9.4 5 9.8 5.4 9.8 5.8L9.8 8.2Z"></path></svg>
                                    Add Another
                                </button>
                            }
                            {isSubmitted && data.pets.length === 0 && data.isPets === "Yes" && <div className="text-danger error-msg">minimum 1 pet is required</div>}


                        </>
                    }
                    <h6 className="mt-4 mb-2 question">Will you have an ADA Qualified service animal or Emotional Support Animal at this property?</h6>
                    <Form.Check
                        inline
                        label="Yes"
                        name="qualifiedService"
                        type='radio'
                        id="qualifiedServiceYes"
                        checked={data.isQualified === 'Yes'}
                        onChange={() => setData({ ...data, isQualified: 'Yes' })}
                        isInvalid={!data.isQualified && isSubmitted}
                    />
                    <Form.Check
                        inline
                        label="No"
                        name="qualifiedService"
                        type="radio"
                        id="qualifiedServiceNo"
                        checked={data.isQualified === 'No'}
                        onChange={() => {
                            setData({ ...data, isQualified: 'No', qualified: [], adaEsaDoc: "" })
                            handleAddBtn("qualified")
                            deleteQualifiedImages()
                        }}
                        isInvalid={!data.isQualified && isSubmitted}
                    />
                    {!data.isQualified && isSubmitted && <Form.Control.Feedback type="invalid" className="text-danger error-msg d-block">Please select an option.</Form.Control.Feedback>}
                    {
                        data.isQualified === 'Yes' &&
                        <>
                            {data.qualified.map((item, index) =>
                                <div key={index} className='applying-item'>
                                    <div>
                                        <h4 className='application-full-name d-flex align-items-start justify-content-between'>
                                            <div className='application-name'>{item.type}</div>
                                        </h4>
                                        <p className='mb-1'><span className='box-sub-title'>Breed: </span>{item.breed}</p>
                                        <p className='mb-1'><span className='box-sub-title'>Age: </span>{item.age} years</p>
                                        <p className='mb-1'><span className='box-sub-title'>Weight: </span>{item.weight} pounds</p>
                                        <p className='mb-1'><span className='box-sub-title'>Spayed / Neutered: </span>{item.spayedOrNeutered}</p>
                                        {
                                            data.qualified[index].image.map((datas, i) => {
                                                return (
                                                    <div key={i} className='border-bottom py-2'>
                                                        <div className='d-flex align-items-center uploaded-img'>
                                                            <img src={typeof datas === "string" ? datas : URL.createObjectURL(datas)} />
                                                            <div className='img-name-show'>{typeof datas === "string" ? getFileName(datas) : datas.name}</div>
                                                            <div className='d-flex ms-auto'>
                                                                <a href={typeof datas === "string" ? datas : URL.createObjectURL(datas)} target='_blank' className='d-block p-1'>
                                                                    <svg xmlns="http://www.w3.org/2000/svg" width="18px" height="20px" viewBox="0 0 24 24" fill="none"><g id="style=linear"><g id="eye-open"><path id="vector" d="M15 12C15 13.6592 13.6592 15 12 15C10.3408 15 9 13.6592 9 12C9 10.3408 10.3408 9 12 9C13.6592 9 15 10.3408 15 12Z" stroke="#033A6D" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" /><path id="vector_2" d="M12 19.27C15.53 19.27 18.82 17.4413 21.11 14.2764C22.01 13.0368 22.01 10.9532 21.11 9.71356C18.82 6.54861 15.53 4.71997 12 4.71997C8.46997 4.71997 5.17997 6.54861 2.88997 9.71356C1.98997 10.9532 1.98997 13.0368 2.88997 14.2764C5.17997 17.4413 8.46997 19.27 12 19.27Z" stroke="#033A6D" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" /></g></g></svg>
                                                                </a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )
                                            })
                                        }
                                        {!editQualified && <div className='d-flex justify-content-between mt-3'>
                                            <button onClick={() => handleEdit("qualified", item, index)} type='button' className='action-btn'>
                                                <svg width="21" height="21" color="#033A6D" viewBox="0 0 21 21" fill="#033A6D"><path d="M18.458.7l1.846 1.845a2.394 2.394 0 010 3.382l-.602.601-.012.016a.664.664 0 01-.015.014l-1.212 1.211-.015.017a.664.664 0 01-.015.015L6.96 19.268a.091.091 0 01-.01.012l-.214.212a.652.652 0 01-.312.174l-5.622 1.32a.652.652 0 01-.784-.785l1.319-5.621a.652.652 0 01.174-.313l.207-.208.01-.011a.665.665 0 01.012-.011L14.454 1.32l.01-.009.611-.612a2.393 2.393 0 013.383 0zM2.422 15.664l-.894 3.811 3.811-.895-2.917-2.916zM13.689 3.929L3.113 14.509l3.38 3.381 10.57-10.571-3.374-3.39zm1.233-1.231l-.311.309 3.375 3.389.318-.316-3.382-3.382zm1.075-1.075l-.153.153 3.382 3.382.155-.153a1.09 1.09 0 000-1.538l-1.845-1.844a1.089 1.089 0 00-1.539 0z"></path></svg>
                                                Edit
                                            </button>
                                            <button onClick={() => handleDelete("qualified", index)} type='button' className='action-btn'>
                                                <svg width="18" height="20" color="#033A6D" viewBox="0 0 18 20" fill="#033A6D"><path d="M12.445 0c.357 0 .646.274.646.612l-.001 1.836 4.317.001a.6.6 0 01.585.513l.008.1a.603.603 0 01-.593.611h-1.044v15.715a.622.622 0 01-.63.612H2.267a.622.622 0 01-.632-.612V3.673H.593a.6.6 0 01-.585-.512L0 3.06c0-.338.266-.612.593-.612l4.316-.001V.612C4.91.274 5.2 0 5.555 0h6.89zm2.656 3.673H2.9v15.103H15.1V3.673zm-9.456 1.94c.34 0 .614.273.614.611v9.796a.613.613 0 01-1.227 0V6.224c0-.338.275-.612.613-.612zm3.273 0c.34 0 .614.273.614.611v9.796a.613.613 0 01-1.227 0V6.224c0-.338.274-.612.613-.612zm3.273 0c.339 0 .614.273.614.611v9.796a.613.613 0 01-1.228 0V6.224c0-.338.275-.612.614-.612zm-.392-4.389H6.201V2.45h5.598V1.224z"></path></svg>
                                                Delete
                                            </button>
                                        </div>}
                                    </div>
                                </div>
                            )}
                            {
                                (addQualified || editQualified) &&
                                <div className='applying-form position-relative'>
                                    {
                                        data.qualified.length !== 0 &&
                                        <button onClick={() => setAddQualified(false)} type='button' className='action-btn delete-form-btn'>
                                            <svg className='m-0' width="18" height="20" color="#033A6D" viewBox="0 0 18 20" fill="#033A6D"><path d="M12.445 0c.357 0 .646.274.646.612l-.001 1.836 4.317.001a.6.6 0 01.585.513l.008.1a.603.603 0 01-.593.611h-1.044v15.715a.622.622 0 01-.63.612H2.267a.622.622 0 01-.632-.612V3.673H.593a.6.6 0 01-.585-.512L0 3.06c0-.338.266-.612.593-.612l4.316-.001V.612C4.91.274 5.2 0 5.555 0h6.89zm2.656 3.673H2.9v15.103H15.1V3.673zm-9.456 1.94c.34 0 .614.273.614.611v9.796a.613.613 0 01-1.227 0V6.224c0-.338.275-.612.613-.612zm3.273 0c.34 0 .614.273.614.611v9.796a.613.613 0 01-1.227 0V6.224c0-.338.274-.612.613-.612zm3.273 0c.339 0 .614.273.614.611v9.796a.613.613 0 01-1.228 0V6.224c0-.338.275-.612.614-.612zm-.392-4.389H6.201V2.45h5.598V1.224z"></path></svg>
                                        </button>
                                    }
                                    <Row>
                                        <Form.Group as={Col} md="12" className='mb-3'>
                                            <Form.Label>Type (Dog, Cat, etc.)</Form.Label>
                                            <Form.Control
                                                type="text"
                                                name="petType"
                                                value={qualifiedData.type}
                                                onChange={(e) => setQualifiedData({ ...qualifiedData, type: e.target.value })}
                                                isInvalid={!qualifiedData.type && isQualifiedSubmitted}
                                            />
                                            <Form.Control.Feedback type="invalid" className="text-danger error-msg">type is required</Form.Control.Feedback>
                                        </Form.Group>
                                        <Form.Group as={Col} md="12" className='mb-3'>
                                            <Form.Label>Breed (Optional)</Form.Label>
                                            <Form.Control
                                                type="text"
                                                name="breed"
                                                value={qualifiedData.breed}
                                                onChange={(e) => setQualifiedData({ ...qualifiedData, breed: e.target.value })}
                                            // isInvalid={!qualifiedData.breed && isQualifiedSubmitted}
                                            />
                                            {/* <Form.Control.Feedback type="invalid" className="text-danger error-msg">breed is required</Form.Control.Feedback> */}
                                        </Form.Group>
                                        <Form.Group as={Col} md="6" className='mb-3'>
                                            <Form.Label>Age (Years)</Form.Label>
                                            <Form.Control
                                                type="number"
                                                name="age"
                                                value={qualifiedData.age}
                                                onChange={(e) => setQualifiedData({ ...qualifiedData, age: e.target.value })}
                                                isInvalid={!qualifiedData.age && isQualifiedSubmitted}
                                            />
                                            <Form.Control.Feedback type="invalid" className="text-danger error-msg">age is required</Form.Control.Feedback>
                                        </Form.Group>
                                        <Form.Group as={Col} md="6" className='mb-3'>
                                            <Form.Label>Weight (Lbs)</Form.Label>
                                            <Form.Control
                                                type="number"
                                                name="weight"
                                                value={qualifiedData.weight}
                                                onChange={(e) => setQualifiedData({ ...qualifiedData, weight: e.target.value })}
                                                isInvalid={!qualifiedData.weight && isQualifiedSubmitted}
                                            />
                                            <Form.Control.Feedback type="invalid" className="text-danger error-msg">weight is required</Form.Control.Feedback>
                                        </Form.Group>
                                        <Form.Group as={Col} md="12" className='mb-3'>
                                            <Form.Label>Spayed / Neutered</Form.Label>
                                            <Form.Check
                                                inline
                                                label="Spayed"
                                                name="spayedOrNeuteredAda"
                                                type='radio'
                                                id="spayedOrNeuteredYes"
                                                checked={qualifiedData.spayedOrNeutered === 'Spayed'}
                                                onChange={() => setQualifiedData({ ...qualifiedData, spayedOrNeutered: 'Spayed' })}
                                            />
                                            <Form.Check
                                                inline
                                                label="Neutered"
                                                name="spayedOrNeuteredAda"
                                                type="radio"
                                                id="spayedOrNeuteredNo"
                                                checked={qualifiedData.spayedOrNeutered === 'Neutered'}
                                                onChange={() => setQualifiedData({ ...qualifiedData, spayedOrNeutered: 'Neutered' })}
                                            />
                                            <Form.Check
                                                inline
                                                label="Neither"
                                                name="spayedOrNeuteredAda"
                                                type="radio"
                                                id="spayedOrNeuteredNo"
                                                checked={qualifiedData.spayedOrNeutered === 'Neither'}
                                                onChange={() => setQualifiedData({ ...qualifiedData, spayedOrNeutered: 'Neither' })}
                                            />
                                        </Form.Group>
                                        <Form.Group as={Col} md="12" className='mb-3 mt-2'>
                                            {qualifiedData.image &&
                                                qualifiedData.image.map((item, i) => {
                                                    return (
                                                        <div key={i} className='border-bottom py-3 mb-2'>
                                                            <div className='d-flex align-items-center uploaded-img'>
                                                                <img src={typeof item === "string" ? item : URL.createObjectURL(item)} />
                                                                <div>{typeof item === "string" ? getFileName(item) : item.name}</div>
                                                                <div className='d-flex ms-auto'>
                                                                    <a href={typeof item === "string" ? item : URL.createObjectURL(item)} target='_blank' className='d-block p-1'>
                                                                        <svg xmlns="http://www.w3.org/2000/svg" width="18px" height="20px" viewBox="0 0 24 24" fill="none"><g id="style=linear"><g id="eye-open"><path id="vector" d="M15 12C15 13.6592 13.6592 15 12 15C10.3408 15 9 13.6592 9 12C9 10.3408 10.3408 9 12 9C13.6592 9 15 10.3408 15 12Z" stroke="#033A6D" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" /><path id="vector_2" d="M12 19.27C15.53 19.27 18.82 17.4413 21.11 14.2764C22.01 13.0368 22.01 10.9532 21.11 9.71356C18.82 6.54861 15.53 4.71997 12 4.71997C8.46997 4.71997 5.17997 6.54861 2.88997 9.71356C1.98997 10.9532 1.98997 13.0368 2.88997 14.2764C5.17997 17.4413 8.46997 19.27 12 19.27Z" stroke="#033A6D" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" /></g></g></svg>
                                                                    </a>
                                                                    <span className='d-block p-1 ms-1' onClick={() => removeQualifiedImage(i)}>
                                                                        <svg width="18" height="20" color="#033A6D" viewBox="0 0 18 20" fill="#033A6D"><path d="M12.445 0c.357 0 .646.274.646.612l-.001 1.836 4.317.001a.6.6 0 01.585.513l.008.1a.603.603 0 01-.593.611h-1.044v15.715a.622.622 0 01-.63.612H2.267a.622.622 0 01-.632-.612V3.673H.593a.6.6 0 01-.585-.512L0 3.06c0-.338.266-.612.593-.612l4.316-.001V.612C4.91.274 5.2 0 5.555 0h6.89zm2.656 3.673H2.9v15.103H15.1V3.673zm-9.456 1.94c.34 0 .614.273.614.611v9.796a.613.613 0 01-1.227 0V6.224c0-.338.275-.612.613-.612zm3.273 0c.34 0 .614.273.614.611v9.796a.613.613 0 01-1.227 0V6.224c0-.338.274-.612.613-.612zm3.273 0c.339 0 .614.273.614.611v9.796a.613.613 0 01-1.228 0V6.224c0-.338.275-.612.614-.612zm-.392-4.389H6.201V2.45h5.598V1.224z"></path></svg>
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )
                                                })
                                            }
                                            <div className='d-flex position-relative flex-wrap'>
                                                <input type='file' className='input-file' id="qualifiedPhoto" value="" accept="image/*" onChange={(e) => handleImage(e, "qualified")} />
                                                <label className="action-btn" htmlFor='qualifiedPhoto'>
                                                    <svg width="18" height="18" viewBox="0 0 18 18"><path d="M9 16.7C13.3 16.7 16.7 13.3 16.7 9 16.7 4.7 13.3 1.3 9 1.3 4.7 1.3 1.3 4.7 1.3 9 1.3 13.3 4.7 16.7 9 16.7ZM9 18C4 18 0 14 0 9 0 4 4 0 9 0 14 0 18 4 18 9 18 14 14 18 9 18ZM9.8 8.2L12.2 8.2C12.6 8.2 13 8.6 13 9 13 9.4 12.6 9.8 12.2 9.8L9.8 9.8 9.8 12.2C9.8 12.6 9.4 13 9 13 8.6 13 8.2 12.6 8.2 12.2L8.2 9.8 5.8 9.8C5.4 9.8 5 9.4 5 9 5 8.6 5.4 8.2 5.8 8.2L8.2 8.2 8.2 5.8C8.2 5.4 8.6 5 9 5 9.4 5 9.8 5.4 9.8 5.8L9.8 8.2Z"></path></svg>
                                                    Upload Image
                                                </label>
                                                {qualifiedData.image.length === 0 && isQualifiedSubmitted && <Form.Control.Feedback type="invalid" className="text-danger error-msg d-block w-100">image is required</Form.Control.Feedback>}
                                            </div>
                                        </Form.Group>
                                    </Row>
                                    <Button onClick={handleQualifiedSave} type="button" className="text-uppercase border-btn d-block mx-auto mt-2" disabled={isQualifiedImageLoader}>{isQualifiedImageLoader ? 'Loading...' : 'Save'}</Button>
                                </div>
                            }
                            {
                                !addQualified && !editQualified &&
                                <button onClick={() => handleAddBtn("qualified")} type='button' className='action-btn'>
                                    <svg className="kzsFVxnNUwoBGhZVibsb" width="18" height="18" viewBox="0 0 18 18"><path d="M9 16.7C13.3 16.7 16.7 13.3 16.7 9 16.7 4.7 13.3 1.3 9 1.3 4.7 1.3 1.3 4.7 1.3 9 1.3 13.3 4.7 16.7 9 16.7ZM9 18C4 18 0 14 0 9 0 4 4 0 9 0 14 0 18 4 18 9 18 14 14 18 9 18ZM9.8 8.2L12.2 8.2C12.6 8.2 13 8.6 13 9 13 9.4 12.6 9.8 12.2 9.8L9.8 9.8 9.8 12.2C9.8 12.6 9.4 13 9 13 8.6 13 8.2 12.6 8.2 12.2L8.2 9.8 5.8 9.8C5.4 9.8 5 9.4 5 9 5 8.6 5.4 8.2 5.8 8.2L8.2 8.2 8.2 5.8C8.2 5.4 8.6 5 9 5 9.4 5 9.8 5.4 9.8 5.8L9.8 8.2Z"></path></svg>
                                    Add Another
                                </button>
                            }
                            {isSubmitted && data.qualified.length === 0 && data.isQualified === "Yes" && <div className="text-danger error-msg">minimum 1 is required</div>}

                            <h6 className="mt-4 mb-2 question">ASA / ESA document (required)</h6>
                            {data.adaEsaDoc ?
                                <div className='border-bottom py-3 mb-2'>
                                    <div className='d-flex align-items-center uploaded-img'>
                                        {/* <div className='d-flex ms-auto'> */}
                                        {/* <a href={typeof data.adaEsaDoc === "string" ? data.adaEsaDoc : URL?.createObjectURL(data.adaEsaDoc)} target='_blank' className='d-block p-1'>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="18px" height="20px" viewBox="0 0 24 24" fill="none"><g id="style=linear"><g id="eye-open"><path id="vector" d="M15 12C15 13.6592 13.6592 15 12 15C10.3408 15 9 13.6592 9 12C9 10.3408 10.3408 9 12 9C13.6592 9 15 10.3408 15 12Z" stroke="#033A6D" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" /><path id="vector_2" d="M12 19.27C15.53 19.27 18.82 17.4413 21.11 14.2764C22.01 13.0368 22.01 10.9532 21.11 9.71356C18.82 6.54861 15.53 4.71997 12 4.71997C8.46997 4.71997 5.17997 6.54861 2.88997 9.71356C1.98997 10.9532 1.98997 13.0368 2.88997 14.2764C5.17997 17.4413 8.46997 19.27 12 19.27Z" stroke="#033A6D" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" /></g></g></svg>
                                            </a> */}
                                        {(getFileExtension(data.adaEsaDoc) === "jpg" || getFileExtension(data.adaEsaDoc) === "jepg" || getFileExtension(data.adaEsaDoc) === "png" || getFileExtension(data.adaEsaDoc) === "svg") ?
                                            <>
                                                <img src={data.adaEsaDoc} />
                                                <a href={data.adaEsaDoc} target='_blank' className='img-name-show ps-1'>{getFileName(data.adaEsaDoc)}</a>
                                            </>
                                            : <a href={data.adaEsaDoc} target='_blank' className='img-name-show'>{getFileName(data.adaEsaDoc)}</a>
                                        }

                                        <span className='d-block p-1 ms-1' onClick={() => deleteSignature()}>
                                            <svg width="18" height="20" color="#033A6D" viewBox="0 0 18 20" fill="#033A6D"><path d="M12.445 0c.357 0 .646.274.646.612l-.001 1.836 4.317.001a.6.6 0 01.585.513l.008.1a.603.603 0 01-.593.611h-1.044v15.715a.622.622 0 01-.63.612H2.267a.622.622 0 01-.632-.612V3.673H.593a.6.6 0 01-.585-.512L0 3.06c0-.338.266-.612.593-.612l4.316-.001V.612C4.91.274 5.2 0 5.555 0h6.89zm2.656 3.673H2.9v15.103H15.1V3.673zm-9.456 1.94c.34 0 .614.273.614.611v9.796a.613.613 0 01-1.227 0V6.224c0-.338.275-.612.613-.612zm3.273 0c.34 0 .614.273.614.611v9.796a.613.613 0 01-1.227 0V6.224c0-.338.274-.612.613-.612zm3.273 0c.339 0 .614.273.614.611v9.796a.613.613 0 01-1.228 0V6.224c0-.338.275-.612.614-.612zm-.392-4.389H6.201V2.45h5.598V1.224z"></path></svg>
                                        </span>
                                        {/* </div> */}
                                    </div>
                                </div> :
                                <div className='d-flex position-relative'>
                                    <input type='file' className='input-file' id="signatureinput" value="" onChange={(e) => handleImage(e, "adaEsa")} />
                                    <label className="action-btn" htmlFor='signatureinput'>
                                        <svg width="18" height="18" viewBox="0 0 18 18"><path d="M9 16.7C13.3 16.7 16.7 13.3 16.7 9 16.7 4.7 13.3 1.3 9 1.3 4.7 1.3 1.3 4.7 1.3 9 1.3 13.3 4.7 16.7 9 16.7ZM9 18C4 18 0 14 0 9 0 4 4 0 9 0 14 0 18 4 18 9 18 14 14 18 9 18ZM9.8 8.2L12.2 8.2C12.6 8.2 13 8.6 13 9 13 9.4 12.6 9.8 12.2 9.8L9.8 9.8 9.8 12.2C9.8 12.6 9.4 13 9 13 8.6 13 8.2 12.6 8.2 12.2L8.2 9.8 5.8 9.8C5.4 9.8 5 9.4 5 9 5 8.6 5.4 8.2 5.8 8.2L8.2 8.2 8.2 5.8C8.2 5.4 8.6 5 9 5 9.4 5 9.8 5.4 9.8 5.8L9.8 8.2Z"></path></svg>
                                        Upload Documnet
                                    </label>
                                </div>
                            }
                        </>
                    }
                    <h6 className="mt-4 mb-2 question">Do you have a fish tank?</h6>
                    <Form.Check
                        inline
                        label="Yes"
                        name="fishTank"
                        type='radio'
                        id="fishTankYes"
                        checked={data.isFishTank === 'Yes'}
                        onChange={() => setData({ ...data, isFishTank: 'Yes' })}
                        isInvalid={!data.isFishTank && isSubmitted}
                    />
                    <Form.Check
                        inline
                        label="No"
                        name="fishTank"
                        type="radio"
                        id="fishTankNo"
                        checked={data.isFishTank === 'No'}
                        onChange={() => setData({ ...data, isFishTank: 'No', fishTank: "" })}
                        isInvalid={!data.isFishTank && isSubmitted}
                    />
                    {!data.isFishTank && isSubmitted && <Form.Control.Feedback type="invalid" className="text-danger error-msg d-block">Please select an option.</Form.Control.Feedback>}
                    {data.isFishTank === "Yes" &&
                        <Form.Group as={Col} md="12" className='mb-3 mt-2'>
                            <Form.Label>Please describe: (gallons etc.)</Form.Label>
                            <Form.Control
                                as="textarea"
                                name="fishtankDetails"
                                style={{ height: '150px' }}
                                value={data.fishTank}
                                onChange={(e) => setData({ ...data, fishTank: e.target.value })}
                                isInvalid={!data.fishTank?.trim() && isSubmitted}
                            />
                            <Form.Control.Feedback type="invalid" className="text-danger error-msg">Description is required</Form.Control.Feedback>
                        </Form.Group>
                    }

                    <h6 className="mt-4 mb-2 question">Will you have a vehicle?</h6>
                    <Form.Check
                        inline
                        label="Yes"
                        name="vehicle"
                        type='radio'
                        id="vehicleYes"
                        checked={data.isVehicles === 'Yes'}
                        onChange={() => setData({ ...data, isVehicles: 'Yes' })}
                        isInvalid={!data.isVehicles && isSubmitted}
                    />
                    <Form.Check
                        inline
                        label="No"
                        name="vehicle"
                        type="radio"
                        id="vehicleNo"
                        checked={data.isVehicles === 'No'}
                        onChange={() => {
                            setData({ ...data, isVehicles: 'No', vehicles: [] })
                            handleAddBtn("vehicle")
                        }}
                        isInvalid={!data.isVehicles && isSubmitted}
                    />
                    {!data.isVehicles && isSubmitted && <Form.Control.Feedback type="invalid" className="text-danger error-msg d-block">Please select an option.</Form.Control.Feedback>}
                    {
                        data.isVehicles === 'Yes' &&
                        <>
                            {data.vehicles.map((item, index) =>
                                <div key={index} className='applying-item'>
                                    <div>
                                        <h4 className='application-full-name d-flex align-items-start justify-content-between'>
                                            <div className='application-name'>{item.name}</div>
                                        </h4>
                                        <p className='mb-1'><span className='box-sub-title'>Model: </span>{item.model}</p>
                                        <p className='mb-1'><span className='box-sub-title'>Color: </span>{item.color}</p>
                                        <p className='mb-1'><span className='box-sub-title'>Year: </span>{item.year}</p>
                                        <p className='mb-1'><span className='box-sub-title'>License Plate: </span>{item.licensePlate}</p>
                                        <div className='d-flex justify-content-between mt-3'>
                                            <button onClick={() => handleEdit("vehicles", item, index)} type='button' className='action-btn'>
                                                <svg width="21" height="21" color="#033A6D" viewBox="0 0 21 21" fill="#033A6D"><path d="M18.458.7l1.846 1.845a2.394 2.394 0 010 3.382l-.602.601-.012.016a.664.664 0 01-.015.014l-1.212 1.211-.015.017a.664.664 0 01-.015.015L6.96 19.268a.091.091 0 01-.01.012l-.214.212a.652.652 0 01-.312.174l-5.622 1.32a.652.652 0 01-.784-.785l1.319-5.621a.652.652 0 01.174-.313l.207-.208.01-.011a.665.665 0 01.012-.011L14.454 1.32l.01-.009.611-.612a2.393 2.393 0 013.383 0zM2.422 15.664l-.894 3.811 3.811-.895-2.917-2.916zM13.689 3.929L3.113 14.509l3.38 3.381 10.57-10.571-3.374-3.39zm1.233-1.231l-.311.309 3.375 3.389.318-.316-3.382-3.382zm1.075-1.075l-.153.153 3.382 3.382.155-.153a1.09 1.09 0 000-1.538l-1.845-1.844a1.089 1.089 0 00-1.539 0z"></path></svg>
                                                Edit
                                            </button>
                                            <button onClick={() => handleDelete("vehicles", index)} type='button' className='action-btn'>
                                                <svg width="18" height="20" color="#033A6D" viewBox="0 0 18 20" fill="#033A6D"><path d="M12.445 0c.357 0 .646.274.646.612l-.001 1.836 4.317.001a.6.6 0 01.585.513l.008.1a.603.603 0 01-.593.611h-1.044v15.715a.622.622 0 01-.63.612H2.267a.622.622 0 01-.632-.612V3.673H.593a.6.6 0 01-.585-.512L0 3.06c0-.338.266-.612.593-.612l4.316-.001V.612C4.91.274 5.2 0 5.555 0h6.89zm2.656 3.673H2.9v15.103H15.1V3.673zm-9.456 1.94c.34 0 .614.273.614.611v9.796a.613.613 0 01-1.227 0V6.224c0-.338.275-.612.613-.612zm3.273 0c.34 0 .614.273.614.611v9.796a.613.613 0 01-1.227 0V6.224c0-.338.274-.612.613-.612zm3.273 0c.339 0 .614.273.614.611v9.796a.613.613 0 01-1.228 0V6.224c0-.338.275-.612.614-.612zm-.392-4.389H6.201V2.45h5.598V1.224z"></path></svg>
                                                Delete
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            )}
                            {
                                (addVehicles || editVehicles) &&
                                <div className='applying-form position-relative'>
                                    {
                                        data.vehicles.length !== 0 &&
                                        <button onClick={() => setAddVehicles(false)} type='button' className='action-btn delete-form-btn'>
                                            <svg className='m-0' width="18" height="20" color="#033A6D" viewBox="0 0 18 20" fill="#033A6D"><path d="M12.445 0c.357 0 .646.274.646.612l-.001 1.836 4.317.001a.6.6 0 01.585.513l.008.1a.603.603 0 01-.593.611h-1.044v15.715a.622.622 0 01-.63.612H2.267a.622.622 0 01-.632-.612V3.673H.593a.6.6 0 01-.585-.512L0 3.06c0-.338.266-.612.593-.612l4.316-.001V.612C4.91.274 5.2 0 5.555 0h6.89zm2.656 3.673H2.9v15.103H15.1V3.673zm-9.456 1.94c.34 0 .614.273.614.611v9.796a.613.613 0 01-1.227 0V6.224c0-.338.275-.612.613-.612zm3.273 0c.34 0 .614.273.614.611v9.796a.613.613 0 01-1.227 0V6.224c0-.338.274-.612.613-.612zm3.273 0c.339 0 .614.273.614.611v9.796a.613.613 0 01-1.228 0V6.224c0-.338.275-.612.614-.612zm-.392-4.389H6.201V2.45h5.598V1.224z"></path></svg>
                                        </button>
                                    }
                                    <Row>
                                        <Form.Group as={Col} className='mb-3 col-6'>
                                            <Form.Label>Make</Form.Label>
                                            <Form.Control
                                                type="text"
                                                name="make"
                                                value={vehicleData.name}
                                                onChange={(e) => setVehicleData({ ...vehicleData, name: e.target.value })}
                                                isInvalid={!vehicleData.name && isVehicleSubmitted}
                                            />
                                            <Form.Control.Feedback type="invalid" className="text-danger error-msg">make is required</Form.Control.Feedback>
                                        </Form.Group>
                                        <Form.Group as={Col} className='mb-3 col-6'>
                                            <Form.Label>Model</Form.Label>
                                            <Form.Control
                                                type="text"
                                                name="model"
                                                value={vehicleData.model}
                                                onChange={(e) => setVehicleData({ ...vehicleData, model: e.target.value })}
                                                isInvalid={!vehicleData.model && isVehicleSubmitted}
                                            />
                                            <Form.Control.Feedback type="invalid" className="text-danger error-msg">model is required</Form.Control.Feedback>
                                        </Form.Group>
                                        <Form.Group as={Col} className='mb-3 col-6'>
                                            <Form.Label>Color</Form.Label>
                                            <Form.Control
                                                type="text"
                                                name="color"
                                                value={vehicleData.color}
                                                onChange={(e) => setVehicleData({ ...vehicleData, color: e.target.value })}
                                                isInvalid={!vehicleData.color && isVehicleSubmitted}
                                            />
                                            <Form.Control.Feedback type="invalid" className="text-danger error-msg">color is required</Form.Control.Feedback>
                                        </Form.Group>
                                        <Form.Group as={Col} className='mb-3 col-6'>
                                            <Form.Label>Year</Form.Label>
                                            <Form.Control
                                                type="number"
                                                name="year"
                                                value={vehicleData.year}
                                                onChange={(e) => setVehicleData({ ...vehicleData, year: e.target.value })}
                                                isInvalid={!vehicleData.year && isVehicleSubmitted}
                                            />
                                            <Form.Control.Feedback type="invalid" className="text-danger error-msg">year is required</Form.Control.Feedback>
                                        </Form.Group>
                                        <Form.Group as={Col} className='mb-3 col-6'>
                                            <Form.Label>License Plate</Form.Label>
                                            <Form.Control
                                                type="text"
                                                name="licensePlate"
                                                value={vehicleData.licensePlate}
                                                onChange={(e) => setVehicleData({ ...vehicleData, licensePlate: e.target.value })}
                                                isInvalid={!vehicleData.licensePlate && isVehicleSubmitted}
                                            />
                                            <Form.Control.Feedback type="invalid" className="text-danger error-msg">License plate is required</Form.Control.Feedback>
                                        </Form.Group>
                                    </Row>
                                    <Button type="button" onClick={handleVehilceSave} className={`${vehicleLoading ? 'loading-btn' : ''} text-uppercase border-btn d-block mx-auto mt-2`}>{vehicleLoading ? <Loader /> : 'Save'}</Button>
                                </div>
                            }

                            {
                                !addVehicles && !editVehicles &&
                                <button onClick={() => handleAddBtn("vehicle")} type='button' className='action-btn'>
                                    <svg className="kzsFVxnNUwoBGhZVibsb" width="18" height="18" viewBox="0 0 18 18"><path d="M9 16.7C13.3 16.7 16.7 13.3 16.7 9 16.7 4.7 13.3 1.3 9 1.3 4.7 1.3 1.3 4.7 1.3 9 1.3 13.3 4.7 16.7 9 16.7ZM9 18C4 18 0 14 0 9 0 4 4 0 9 0 14 0 18 4 18 9 18 14 14 18 9 18ZM9.8 8.2L12.2 8.2C12.6 8.2 13 8.6 13 9 13 9.4 12.6 9.8 12.2 9.8L9.8 9.8 9.8 12.2C9.8 12.6 9.4 13 9 13 8.6 13 8.2 12.6 8.2 12.2L8.2 9.8 5.8 9.8C5.4 9.8 5 9.4 5 9 5 8.6 5.4 8.2 5.8 8.2L8.2 8.2 8.2 5.8C8.2 5.4 8.6 5 9 5 9.4 5 9.8 5.4 9.8 5.8L9.8 8.2Z"></path></svg>
                                    Add Another
                                </button>
                            }
                            {isSubmitted && data.vehicles.length === 0 && data.isVehicles === "Yes" && <div className="text-danger error-msg">minimum 1 vehicle is required</div>}
                        </>
                    }

                    <h6 className="mt-4 mb-2 question">Do you smoke?</h6>
                    <Form.Check
                        inline
                        label="Yes"
                        name="smoke"
                        type='radio'
                        id="smokeYes"
                        checked={data.isSmoking === 'Yes'}
                        onChange={() => setData({ ...data, isSmoking: 'Yes' })}
                        isInvalid={!data.isSmoking && isSubmitted}
                    />
                    <Form.Check
                        inline
                        label="Outdoors Only"
                        name="smoke"
                        type="radio"
                        id="smokeOutdoors"
                        checked={data.isSmoking === 'Outdoors Only'}
                        onChange={() => setData({ ...data, isSmoking: 'Outdoors Only' })}
                        isInvalid={!data.isSmoking && isSubmitted}
                    />
                    <Form.Check
                        inline
                        label="No"
                        name="smoke"
                        type="radio"
                        id="smokeNo"
                        checked={data.isSmoking === 'No'}
                        onChange={() => setData({ ...data, isSmoking: 'No' })}
                        isInvalid={!data.isSmoking && isSubmitted}
                    />
                    {!data.isSmoking && isSubmitted && <Form.Control.Feedback type="invalid" className="text-danger error-msg d-block">Please select an option.</Form.Control.Feedback>}
                    <h6 className="mt-4 mb-2 question">Do you have any special requests or requirements we should be aware of?</h6>
                    <Form.Check
                        inline
                        label="Yes"
                        name="specialrequests"
                        type='radio'
                        id="specialrequestsYes"
                        checked={data.isAnySpecialRequest === 'Yes'}
                        onChange={() => setData({ ...data, isAnySpecialRequest: 'Yes' })}
                        isInvalid={!data.isAnySpecialRequest && isSubmitted}
                    />
                    <Form.Check
                        inline
                        label="No"
                        name="specialrequests"
                        type="radio"
                        id="specialrequestsNo"
                        checked={data.isAnySpecialRequest === 'No'}
                        onChange={() => setData({ ...data, isAnySpecialRequest: 'No', specialRequest: "" })}
                        isInvalid={!data.isAnySpecialRequest && isSubmitted}
                    />
                    {!data.isAnySpecialRequest && isSubmitted && <Form.Control.Feedback type="invalid" className="text-danger error-msg d-block">Please select an option.</Form.Control.Feedback>}
                    {data.isAnySpecialRequest === "Yes" &&
                        <Form.Group as={Col} md="12" className='mb-3 mt-2'>
                            <Form.Label>Please explain:</Form.Label>
                            <Form.Control
                                as="textarea"
                                name="moving"
                                style={{ height: '150px' }}
                                value={data.specialRequest}
                                onChange={(e) => setData({ ...data, specialRequest: e.target.value })}
                                isInvalid={!data.specialRequest && isSubmitted}
                            />
                            <Form.Control.Feedback type="invalid" className="text-danger error-msg">explaination is required</Form.Control.Feedback>
                        </Form.Group>
                    }

                    <Button
                        type="submit"
                        // disabled={data.isQualified === 'Yes' && !data.adaEsaDoc} 
                        disabled={isMainLoader}
                        className='text-uppercase step-button d-block mx-auto mt-4'>
                        {isMainLoader ? <Loader /> : 'Next'}
                    </Button>
                    {isAnyError.value && <p className="text-danger error-msg m-2 text-center">All fields not completed</p>}
                </Form>
            </section>
        </>
    )
});

export default StepSix