import AWS from 'aws-sdk';
import { Auth } from 'aws-amplify';

// Function to get an S3 instance with the current user's credentials
async function getS3Instance() {
  const credentials = await Auth.currentCredentials();
  AWS.config.update({
    region: process.env.REACT_APP_S3_REGION,
    credentials: Auth.essentialCredentials(credentials)
  });

  return new AWS.S3({
    params: { Bucket: process.env.REACT_APP_S3_BUCKET }
  });
}

// Export a function that returns the S3 instance
export default getS3Instance;
